import React, {useState} from 'react';
import Logo from '../Logo/Logo';
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const Menu = (props) => {
  const [active, setActive] = useState(false);

  const scrollToAnchor = (anchor) => {
    gsap.to(window, {duration: 2, scrollTo: `#${anchor}`});
    setActive(!active);
  }

  return (
    <React.Fragment>
      <div className="alfa-menu--button" 
        onClick={() => setActive(!active)} 
        data-active={active}>
        <div className="alfa-menu--button_bar" data-position="1"></div>
        <div className="alfa-menu--button_bar" data-position="2"></div>
        <div className="alfa-menu--button_bar" data-position="3"></div>
      </div>
      
      <div className="alfa-menu" data-active={active}>
        <div className="alfa-menu--logo">
          <Logo primary="white" secondary="gold" />
        </div>
          
        <div className="alfa-menu--list_wrapper">
          <div className="alfa-menu--list_inner">
            <ul className="alfa-menu--list">
              <li className="alfa-menu--list_item">
                <a className="alfa-menu--list_anchor" 
                  href="#about" 
                  onClick={(evt) => {
                    evt.preventDefault(); 
                    scrollToAnchor('about');
                  }}>About</a>
              </li>
              <li className="alfa-menu--list_item">
                <a className="alfa-menu--list_anchor" 
                  href="#programs" 
                  onClick={(evt) => {
                    evt.preventDefault(); 
                    scrollToAnchor('programs');
                  }}>Programs</a>
              </li>
              <li className="alfa-menu--list_item">
                <a className="alfa-menu--list_anchor" 
                  href="#team"
                  onClick={(evt) => {
                    evt.preventDefault(); 
                    scrollToAnchor('team');
                  }}>Team</a>
              </li>
            </ul>
            
            <ul className="alfa-menu--list">
              <li className="alfa-menu--list_item">
                <a className="alfa-menu--list_anchor" href="mailto:info@alfafastpitch.com">info@alfafastpitch.com</a>
              </li>
              <li className="alfa-menu--list_item">
                <a className="alfa-menu--list_anchor" 
                  href="#partners"
                  onClick={(evt) => {
                    evt.preventDefault(); 
                    scrollToAnchor('partners');
                  }}>Partners</a>
              </li>
              <li className="alfa-menu--list_item">
                <a
                  className="alfa-menu--list_anchor" 
                  href="https://www.paypal.com/donate/?hosted_button_id=FWPGL2QAPWUPA" 
                  target="_blank" rel="noreferrer">donate</a>
              </li>
            </ul>
          </div>
          
          <ul className="alfa-menu--list">
            <li className="alfa-menu--list_item">
              <p className="alfa-menu--list_heading">Have more questions?</p>
              <p className="alfa-menu--list_heading">We are here to help.</p>
            </li>
            <li className="alfa-menu--list_item">
              <button className="alfa-action" data-secondary-gold  onClick={() => { window.open('https://share.hsforms.com/1jmio6mmoQdSRSfjV2OIs9Qcqy0n')}}>Contact Us</button>
            </li>
          </ul>
        </div>

        <div className="alfa-donate">
          <div className="alfa-donate--text">
            <p className="alfa-donate--statement">There are opportunities to improve the athlete journey throughout their life.</p>
            <a href="https://www.paypal.com/donate/?hosted_button_id=FWPGL2QAPWUPA" className="alfa-action" target="_blank" rel="noreferrer">Donate</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Menu;
