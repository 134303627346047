import React, {useState, useRef, useEffect} from 'react';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import data from '../../scripts/staff-board.json';

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Carousel = (props) => {
  const [active, setActive] = useState(true);
  const [moveAmount, setMoveAmount] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesTotal, setSlidesTotal] = useState(null);
  const [staffData] = useState(data[0].staff);
  const [boardData] = useState(data[1].board);
  const [isStaff, setIsStaff] = useState(true);
  const [dataLength, setDataLength] = useState(null);
  const [viewport, setViewport] = useState(window.innerWidth);
  const [sliderFinished, setSliderFinished] = useState(false);

  const sliderRef = useRef();

  const moveCarousel = (direction) => {
    if(direction === 'forward') {
      let curSlide = currentSlide + 1;
      setCurrentSlide(curSlide);

      if(curSlide > slidesTotal) {
        console.log('moveAmount', (curSlide * (parseInt(moveAmount))));
        setSlidesTotal(Math.floor(dataLength / 2));
        setCurrentSlide(Math.floor(dataLength / 2));
        setSliderFinished(true);
        sliderRef.current.style.left = `-${(curSlide * parseInt(moveAmount))}px`;
      }else{
        const deltaAmount = `-${(curSlide * parseInt(moveAmount))}`;
        const offsetAmount = `${parseInt(deltaAmount) + ((parseInt(moveAmount) / 2) + 10)}px`;

        if(curSlide >= slidesTotal && (viewport >= 768 && viewport < 1024)) {
          sliderRef.current.style.left = offsetAmount;
        }else{
          sliderRef.current.style.left = `-${(curSlide * parseInt(moveAmount))}px`;
        }
      }
    }else if(direction === 'reverse') {
      let curSlide = currentSlide - 1;
      setCurrentSlide(curSlide);

      if(curSlide >= 0) {
        sliderRef.current.style.left = `-${(curSlide * parseInt(moveAmount))}px`;
      }else{
        curSlide = 0;
        setCurrentSlide(curSlide);
      }
    };
  }

  useEffect(() => { 
    const currentData = isStaff ? staffData.length : boardData.length;
    const imageWrapper = window.getComputedStyle(document.querySelector('.alfa-carousel--gallery_images-wrapper'));
    
    sliderRef.current.style.left = 0;
    setViewport(window.innerWidth);
    setCurrentSlide(0);
    setMoveAmount(imageWrapper.width);
    setDataLength(currentData);

    if(viewport >= 1024) {
      (isStaff) ? setSlidesTotal(Math.ceil(currentData / 2)) : setSlidesTotal(currentData / 2);
    }else{
      (isStaff) ? setSlidesTotal(Math.ceil(currentData - 1)) : setSlidesTotal(currentData - 1);
    }
    
  }, [isStaff, staffData, boardData, viewport, slidesTotal, dataLength, sliderFinished]);

  useEffect(() => {
    gsap.to(".alfa-carousel--header", {
      scrollTrigger: {
        trigger: ".alfa-carousel",
        start: "top center",
        // markers: true
      },
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
    
    gsap.to(".alfa-switch", {
      scrollTrigger: {
        trigger: ".alfa-carousel",
        start: "top center",
        // markers: true
      },
      delay: .5,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
    
    gsap.to(".alfa-carousel--wrapper", {
      scrollTrigger: {
        trigger: ".alfa-carousel",
        start: "top center",
        // markers: true
      },
      delay: .75,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
  }, [])

  return (
    <div id="team" className="alfa-carousel">
      <div className="alfa-carousel--header">
        <p className="alfa-carousel--heading">We are female athletes investing in better opportunities for the next generation</p>
      </div>
      
      <div className="alfa-switch">
        <button className="alfa-switch--button" data-active={active} onClick={() => {setActive(!active); setIsStaff(true);}}>Staff</button>
        <button className="alfa-switch--button" data-active={!active} onClick={() => {setActive(!active); setIsStaff(false);}}>Board</button>
        <div className="alfa-switch--pill" data-active={active}></div>
      </div>

      <div className="alfa-carousel--wrapper">
        <div className="alfa-carousel--arrows">
          <div className="alfa-carousel--arrows_right" onClick={() => moveCarousel('forward')}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M8.793 5h1.414l7 7-7 7H8.793l7-7z"/>
              <path fill="none" d="M0 0h24v24H0z"/>
            </svg>
          </div>
          
          <div className="alfa-carousel--arrows_left" onClick={() => moveCarousel('reverse')}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M6.793 12l7-7h1.414l-7 7 7 7h-1.414z"/>
              <path fill="none" d="M0 0h24v24H0z"/>
            </svg>
          </div>
        </div>
      
        <div className="alfa-carousel--gallery">
          <div ref={sliderRef} className="alfa-carousel--gallery_images">
            {(isStaff === true) && staffData.map((staff, index) => 
              <div key={index} className="alfa-carousel--gallery_images-wrapper">
                <div className="alfa-carousel--gallery_card">
                  <img className="alfa-carousel--gallery_card-image" src={staff.src} alt={staff.name} />
                  <p className="alfa-carousel--gallery_card-heading">{staff.name}</p>
                  <p className="alfa-carousel--gallery_card-subheading">{staff.title}</p>
                </div>
              </div>
            )}
            
            {(isStaff === false) && boardData.map((board, index) => 
              <div key={index} className="alfa-carousel--gallery_images-wrapper">
                <div className="alfa-carousel--gallery_card">
                  <img className="alfa-carousel--gallery_card-image" src={board.src} alt={board.name} />
                  <p className="alfa-carousel--gallery_card-heading">{board.name}</p>
                  <p className="alfa-carousel--gallery_card-subheading">{board.title}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Carousel;