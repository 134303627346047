import './styles/app.scss';
import Layout from './components/Layout/Layout';
import Banner from './components/Banner/Banner';
import Introduction from './components/Introduction/Introduction';
import Split from './components/Split/Split';
import Split2 from './components/Split2/Split2';
import Slider from './components/Slider/Slider';
import Carousel from './components/Carousel/Carousel';
import Strip from './components/Strip/Strip';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <Layout>
      <Banner />
      <Introduction />
      <Split />
      <Split2 />
      <Slider />
      <Carousel />
      <Strip />
      <Footer />
    </Layout>
  );
}

export default App;