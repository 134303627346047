import React, {useEffect, useState} from 'react';
import gsap from "gsap";

const Split = () => {
  const [viewport, setViewport] = useState(window.innerWidth);
  
  window.addEventListener('resize', () => {
    setTimeout(() => {
      setViewport(window.innerWidth);
    }, 500);
  });

  useEffect(() => {
    gsap.to(".alfa-split--photo_program", {
      scrollTrigger: {
        trigger: ".alfa-split",
        start: "top center",
        // markers: true
      },
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '-5%'
    });
    
    if(viewport >= 1900) {
      gsap.to(".alfa-split--photo_featured", {
        scrollTrigger: {
          trigger: ".alfa-split",
          start: "top center",
          // markers: true
        },
        delay: .5,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        top: '45%'
      });
    } else {
      gsap.to(".alfa-split--photo_featured", {
        scrollTrigger: {
          trigger: ".alfa-split",
          start: "top center",
          // markers: true
        },
        delay: .5,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        top: '45%'
      });
    }
    
    if(viewport >= 1900) {
      gsap.to(".alfa-split--photo_pixel", {
        scrollTrigger: {
          trigger: ".alfa-split",
          start: "top center",
          // markers: true
        },
        delay: .75,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        bottom: '100px'
      });
    }else if(viewport >= 1024) {
      gsap.to(".alfa-split--photo_pixel", {
        scrollTrigger: {
          trigger: ".alfa-split",
          start: "top center",
          // markers: true
        },
        delay: .75,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        bottom: '105px'
      });
    }else if(viewport >= 768) {
      gsap.to(".alfa-split--photo_pixel", {
        scrollTrigger: {
          trigger: ".alfa-split",
          start: "top center",
          // markers: true
        },
        delay: .75,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        bottom: '25px'
      });
    }else{
      gsap.to(".alfa-split--photo_pixel", {
        scrollTrigger: {
          trigger: ".alfa-split",
          start: "top center",
          // markers: true
        },
        delay: .75,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        bottom: '110px'
      });
    }
    
    gsap.to(".alfa-split--content_header", {
      scrollTrigger: {
        trigger: ".alfa-split",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });

    gsap.to(".alfa-split--content_heading", {
      scrollTrigger: {
        trigger: ".alfa-split",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });

    gsap.to(".alfa-split--content_subheading", {
      scrollTrigger: {
        trigger: ".alfa-split",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
   
    gsap.to(".alfa-split--content_copy", {
      scrollTrigger: {
        trigger: ".alfa-split",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
    
    gsap.to(".alfa-action.split", {
      scrollTrigger: {
        trigger: ".alfa-split",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
  });

  return (
    <div id="programs" className="alfa-split">
      <div className="alfa-split--photo">
        <div className="alfa-split--photo_featured"></div>
        <div className="alfa-split--photo_program"></div>
        <div className="alfa-split--photo_pixel"></div>
      </div>

      <div className="alfa-split--content">
        <div className="alfa-split--content_wrapper">
          <p className="alfa-split--content_header">How we champion growth: [Programs]</p>
          <h3 className="alfa-split--content_heading">ALFAid</h3>
          <h4 className="alfa-split--content_subheading">Youth Financial Assistance</h4>
          <p className="alfa-split--content_copy">ALFAid provides financial assistance and resources 
            to athletes and teams who want to play the game, but do not have the economic means to do so. 
            Athletes and teams in financial need or hardship can leverage ALFAid to pay club dues and/or 
            memberships fees, purchase essential equipment and enter events.</p>
          <button className="alfa-action split" style={{"textTransform":"unset"}} data-secondary onClick={() => window.open('https://share.hsforms.com/1Z8_iBQj8TP-b8emyYaqLjgcqy0n')}>APPLY TO ALFAid</button>
        </div>
      </div>
    </div>
  )
}

export default Split;