import React, {useRef, useEffect, useState} from 'react';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Slider = (props) => {
  const [viewport, setViewport] = useState(window.innerWidth);
  const [currentSlide, setCurrentSlide] = useState(1);
  const startSlide = 1;
  const delta = 1;
  const slidesTotal = 4;
  const sliderRef = useRef();

  
  window.addEventListener('resize', () => {
    setTimeout(() => {
      setViewport(window.innerWidth);
    }, 500);
  });
 
  const setCurrentPosition = (currentPosition) => {
    return currentSlide === currentPosition;
  }

  useEffect(() => {
    if(currentSlide <= slidesTotal) {
      setTimeout(() => {
        sliderRef.current.setAttribute('data-position', currentSlide);
        setCurrentSlide(currentSlide + delta);
      }, 7000);
    }else{
      setCurrentSlide(startSlide);
    }
  }, [currentSlide]);

  useEffect(() => {
    if(viewport >= 1900) {
      gsap.to(".alfa-slider--quote", {
        scrollTrigger: {
          trigger: ".alfa-slider",
          start: "top 75%",
          // markers: true
        },
        delay: 1,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        top: '70'
      });
    }else{
      gsap.to(".alfa-slider--quote", {
        scrollTrigger: {
          trigger: ".alfa-slider",
          start: "top 75%",
          // markers: true
        },
        delay: 1,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        top: '40'
      });
    }
    
    gsap.to(".alfa-slider--slides", {
      scrollTrigger: {
        trigger: ".alfa-slider",
        start: "top 75%",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
    
    if(viewport >= 1900) {
      gsap.to(".alfa-slider--position", {
        scrollTrigger: {
          trigger: ".alfa-slider",
          start: "top 75%",
          // markers: true
        },
        delay: 1,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        bottom: '100'
      });
    }else{
      gsap.to(".alfa-slider--position", {
        scrollTrigger: {
          trigger: ".alfa-slider",
          start: "top 75%",
          // markers: true
        },
        delay: 1,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        bottom: '50'
      });
    }
  });

  return (
    <div className="alfa-slider">
      <div className="alfa-slider--quote">
        <img src="images/alpha-quote-markers.png" alt="" />
      </div>
    
      <div ref={sliderRef} className="alfa-slider--slides" data-position={currentSlide}>
        <div className="alfa-slider--slide">
          <div className="alfa-slider--slide_testimonial">ALFA fills many gaps in our little but big softball community. 
            The opportunities, resources and aid ALFA provides will make a huge impact on athletes and families and is 
            way overdue in our sport.
            <p className="alfa-slider--slide_testimonial_name">-- Natasha Watley, VP Programs</p>
          </div>
        </div>
  
        <div className="alfa-slider--slide">
          <div className="alfa-slider--slide_testimonial">ALFA provides a lifetime softball network to current and former 
            players with robust resources when they need them and the options to give back when they can.
            <p className="alfa-slider--slide_testimonial_name">-- Vanessa Laughlin, CEO</p>
          </div>
        </div>
      
        <div className="alfa-slider--slide">
          <div className="alfa-slider--slide_testimonial">ALFA will open up doors and opportunities for softball players 
            and softball families across the country, and I can't wait to see the ripple effect from its impact 
            throughout our sport!
            <p className="alfa-slider--slide_testimonial_name">-- Amanda Scarborough, Board of Directors</p>
          </div>
        </div>
        
        <div className="alfa-slider--slide">
          <div className="alfa-slider--slide_testimonial">ALFA has been needed in the softball world, and truly 
            is needed across the entire sports world. Our sport should be accessible at all times, and with 
            ALFAid, more girls can experience the magic that softball is!
            <p className="alfa-slider--slide_testimonial_name">-- Lauren Chamberlain, Board of Directors</p>
          </div>
        </div>
      </div>
      
      <div className="alfa-slider--position">
        <div className="alfa-slider--position_marker" data-active={setCurrentPosition(1)}></div>
        <div className="alfa-slider--position_marker" data-active={setCurrentPosition(2)}></div>
        <div className="alfa-slider--position_marker" data-active={setCurrentPosition(3)}></div>
        <div className="alfa-slider--position_marker" data-active={setCurrentPosition(4)}></div>
      </div>
    </div>
  )
}

export default Slider;