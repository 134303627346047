import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Logo = (props) => {
  const primary = props.primary;
  const secondary = props.secondary;

  useEffect(() => {
    gsap.to(".alfa-logo[data-position='banner']", {
      duration: 2,
      opacity: 1,
      top: '-5'
    });
    
    gsap.to(".alfa-logo[data-position='footer']", {
      scrollTrigger: {
        trigger: ".alfa-footer",
        start: "top 75%",
      },
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
  }, []);

  return (
    <div className="alfa-logo" data-position={props.position}>
      <svg className="alfa-logo--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 364.46 124.28">
        <path className={`alfa-logo--${primary}`} d="M18.12,126.94H14.59V126h8.13v.92H19.18v9.13H18.12Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M35,126v10h-1v-4.64H27.63v4.64H26.57V126h1.06v4.48h6.29V126Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M47.51,135.16v.91H40.4V126h6.89v.92H41.46v3.58h5.2v.9h-5.2v3.74Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M64.38,133.39h-5.6l-1.21,2.68h-1.1l4.59-10h1l4.6,10H65.59Zm-.39-.86-2.41-5.4-2.41,5.4Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M70.53,126h1.06v9.14h5.63v.91H70.53Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M81.14,126H82.2v9.14h5.63v.91H81.14Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M91.76,126h1.06v10H91.76Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M104.55,133.39H99l-1.21,2.68h-1.1l4.59-10h1l4.6,10h-1.12Zm-.39-.86-2.41-5.4-2.41,5.4Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M119.1,126v10h-.88l-6.46-8.15v8.15H110.7V126h.87l6.48,8.16V126Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M126.2,135.5a4.86,4.86,0,0,1-1.88-1.83,5.39,5.39,0,0,1,0-5.24,5,5,0,0,1,1.89-1.84,5.51,5.51,0,0,1,2.7-.66,5.56,5.56,0,0,1,2.07.38,4.3,4.3,0,0,1,1.61,1.09l-.68.67a4,4,0,0,0-3-1.19,4.3,4.3,0,0,0-2.17.55,4,4,0,0,0-1.53,1.49,4.38,4.38,0,0,0,0,4.25,4.08,4.08,0,0,0,1.53,1.5,4.39,4.39,0,0,0,2.17.54,4,4,0,0,0,3-1.2l.68.67a4.28,4.28,0,0,1-1.62,1.1,5.64,5.64,0,0,1-2.07.38A5.51,5.51,0,0,1,126.2,135.5Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M144.12,135.16v.91H137V126h6.89v.92h-5.83v3.58h5.2v.9h-5.2v3.74Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M156.38,135.49a4.85,4.85,0,0,1-1.9-1.83,5.37,5.37,0,0,1,0-5.23,5,5,0,0,1,1.9-1.83,5.57,5.57,0,0,1,2.7-.67,5.51,5.51,0,0,1,2.7.66,5,5,0,0,1,1.89,1.84,5.39,5.39,0,0,1,0,5.24,4.89,4.89,0,0,1-1.89,1.83,5.81,5.81,0,0,1-5.4,0Zm4.86-.82a3.93,3.93,0,0,0,1.51-1.49,4.49,4.49,0,0,0,0-4.27,4,4,0,0,0-1.51-1.49,4.33,4.33,0,0,0-2.16-.54,4.38,4.38,0,0,0-2.16.54,4,4,0,0,0-1.52,1.49,4.42,4.42,0,0,0,0,4.27,4,4,0,0,0,1.52,1.49,4.38,4.38,0,0,0,2.16.54A4.33,4.33,0,0,0,161.24,134.67Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M170,126.94v3.95h5.2v.92H170v4.26h-1.07V126h6.9v.92Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M187.12,126.94v3.95h5.2v.92h-5.2v4.26h-1.06V126H193v.92Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M203.13,133.39h-5.6l-1.21,2.68h-1.1l4.59-10h1.05l4.6,10h-1.12Zm-.39-.86-2.41-5.4-2.41,5.4Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M209.9,135.8a3.85,3.85,0,0,1-1.58-1l.41-.82a4.06,4.06,0,0,0,1.45.89,5.47,5.47,0,0,0,1.87.34,3.43,3.43,0,0,0,2-.5,1.52,1.52,0,0,0,.67-1.29,1.3,1.3,0,0,0-.36-1,2.57,2.57,0,0,0-.9-.55,14.25,14.25,0,0,0-1.48-.42,16.28,16.28,0,0,1-1.81-.56,2.84,2.84,0,0,1-1.16-.81,2.16,2.16,0,0,1-.48-1.48,2.53,2.53,0,0,1,.4-1.38,2.74,2.74,0,0,1,1.24-1,5,5,0,0,1,2.07-.38,6,6,0,0,1,1.68.24,4.84,4.84,0,0,1,1.43.66l-.36.84a5.17,5.17,0,0,0-2.75-.83,3.24,3.24,0,0,0-2,.51,1.57,1.57,0,0,0-.67,1.31,1.27,1.27,0,0,0,.37,1,2.3,2.3,0,0,0,.92.56c.36.13.86.27,1.48.43a16.43,16.43,0,0,1,1.79.53,3,3,0,0,1,1.16.81,2.15,2.15,0,0,1,.47,1.46,2.48,2.48,0,0,1-.41,1.38,2.67,2.67,0,0,1-1.26,1,5.13,5.13,0,0,1-2.08.37A6.47,6.47,0,0,1,209.9,135.8Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M222.08,126.94h-3.54V126h8.13v.92h-3.53v9.13h-1.06Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M237.3,126.94a3.47,3.47,0,0,1,0,5.05,4.56,4.56,0,0,1-3,.91h-2.7v3.17h-1.06V126h3.76A4.56,4.56,0,0,1,237.3,126.94Zm-.76,4.37a2.27,2.27,0,0,0,.79-1.84,2.32,2.32,0,0,0-.79-1.88,3.54,3.54,0,0,0-2.28-.65h-2.68v5h2.68A3.48,3.48,0,0,0,236.54,131.31Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M242.84,126h1.07v10h-1.07Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M251.29,126.94h-3.53V126h8.12v.92h-3.53v9.13h-1.06Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M261.26,135.5a4.86,4.86,0,0,1-1.88-1.83,5.39,5.39,0,0,1,0-5.24,5,5,0,0,1,1.89-1.84,5.51,5.51,0,0,1,2.7-.66,5.56,5.56,0,0,1,2.07.38,4.3,4.3,0,0,1,1.61,1.09l-.68.67a4,4,0,0,0-3-1.19,4.3,4.3,0,0,0-2.17.55,4,4,0,0,0-1.53,1.49,4.38,4.38,0,0,0,0,4.25,4.08,4.08,0,0,0,1.53,1.5,4.39,4.39,0,0,0,2.17.54,3.94,3.94,0,0,0,3-1.2l.68.67a4.28,4.28,0,0,1-1.62,1.1,5.64,5.64,0,0,1-2.07.38A5.51,5.51,0,0,1,261.26,135.5Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M280.47,126v10h-1.05v-4.64h-6.29v4.64h-1.06V126h1.06v4.48h6.29V126Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M298.12,133.39h-5.6l-1.2,2.68h-1.11l4.6-10h1l4.6,10h-1.12Zm-.39-.86-2.41-5.4-2.41,5.4Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M305.72,126.94h-3.53V126h8.13v.92h-3.54v9.13h-1.06Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M322.57,126v10h-1.05v-4.64h-6.29v4.64h-1.06V126h1.06v4.48h6.29V126Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M328,126h1.06v9.14h5.63v.91H328Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M345.72,135.16v.91h-7.11V126h6.89v.92h-5.83v3.58h5.2v.9h-5.2v3.74Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M352.33,126.94h-3.54V126h8.13v.92h-3.53v9.13h-1.06Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M367.88,135.16v.91h-7.11V126h6.89v.92h-5.83v3.58H367v.9h-5.2v3.74Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M373.16,135.8a3.83,3.83,0,0,1-1.59-1L372,134a3.89,3.89,0,0,0,1.45.89,5.37,5.37,0,0,0,1.86.34,3.43,3.43,0,0,0,2-.5,1.52,1.52,0,0,0,.67-1.29,1.3,1.3,0,0,0-.36-1,2.57,2.57,0,0,0-.9-.55,13.56,13.56,0,0,0-1.48-.42,16.28,16.28,0,0,1-1.81-.56,2.81,2.81,0,0,1-1.15-.81,2.12,2.12,0,0,1-.49-1.48,2.46,2.46,0,0,1,.41-1.38,2.71,2.71,0,0,1,1.23-1,5.07,5.07,0,0,1,2.07-.38,6.05,6.05,0,0,1,1.69.24,5,5,0,0,1,1.43.66l-.36.84a4.86,4.86,0,0,0-1.35-.62,5,5,0,0,0-1.41-.21,3.26,3.26,0,0,0-2,.51,1.6,1.6,0,0,0-.67,1.31,1.31,1.31,0,0,0,.37,1,2.3,2.3,0,0,0,.92.56c.37.13.86.27,1.48.43a15.78,15.78,0,0,1,1.79.53,3,3,0,0,1,1.16.81,2.15,2.15,0,0,1,.47,1.46,2.4,2.4,0,0,1-.41,1.38,2.69,2.69,0,0,1-1.25,1,5.18,5.18,0,0,1-2.09.37A6.41,6.41,0,0,1,373.16,135.8Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M137.74,107.58H123.32l-9-20h0L93.37,41.37,72.48,87.55h0l-9,20H49l44.38-95.7Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${secondary}`} d="M150.23,14.11h13.31v93.47H150.23Zm20.91,93.47V94.23h30.12v13.35Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M211,54.36h46.6V67.52H224.35v40.06H211Z" transform="translate(-14.59 -11.88)"/>
        <path className={`alfa-logo--${primary}`} d="M343.48,107.58H329.06l-9-20H320L299.11,41.37l-20.9,46.18h0l-9,20H254.73l44.38-95.7Z" transform="translate(-14.59 -11.88)"/>
        <rect className={`alfa-logo--${secondary}`} x="196.45" y="2.23" width="53.25" height="13.35"/>
      </svg>
    </div>
  );
}

Logo.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  position: PropTypes.string
}

Logo.defaultProps = {
  primary: 'white',
  secondary: 'gold',
  position: 'banner',
}

export default Logo;