import React, {useEffect} from 'react';
import Logo from '../Logo/Logo';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import Modal from '../Modal/Modal';
// import Contact from '../Contact/Contact';

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Footer = () => {
  // const [active, setActive] = useState();

  const scrollToAnchor = (anchor) => {
    gsap.to(window, {duration: 2, scrollTo: `#${anchor}`});
  }

  useEffect(() => {
    gsap.to(".alfa-footer--content_block.questions", {
      scrollTrigger: {
        trigger: ".alfa-footer",
        start: "top 75%",
      },
      delay: .75,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
    
    gsap.to(".alfa-footer--content_list-wrapper.contact", {
      scrollTrigger: {
        trigger: ".alfa-footer",
        start: "top 75%",
      },
      delay: .5,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '-20px'
    });
    
    gsap.to(".alfa-footer--copyright_details", {
      scrollTrigger: {
        trigger: ".alfa-footer",
        start: "top 75%",
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
  }, []);

  return (
    <React.Fragment>
      {/* <Modal header="Contact Us" active={active} setActive={setActive}>
        <Contact />
      </Modal> */}
      <div  id="contact" className="alfa-footer">
        <div className="alfa-footer--content">
          <div className="alfa-footer--content_block">
            <Logo primary="teal" secondary="gold" position="footer" />
          </div>
          
          <div className="alfa-footer--content_block">
            <div className="alfa-footer--content_block-wrapper">
              <div className="alfa-footer--content_list-wrapper contact">
                <ul className="alfa-footer--content_list">
                  <li className="alfa-footer--content_list-item">
                    <a className="alfa-footer--content_list-link" 
                      href="#about" 
                      onClick={(evt) => {
                        evt.preventDefault(); 
                        scrollToAnchor('about');
                    }}>About</a>
                  </li>
                  <li className="alfa-footer--content_list-item">
                    <a className="alfa-footer--content_list-link" 
                      href="#programs"
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.preventDefault(); 
                        scrollToAnchor('programs');
                    }}>Programs</a>
                  </li>
                  <li className="alfa-footer--content_list-item">
                    <a className="alfa-footer--content_list-link" 
                      href="#team"
                      onClick={(evt) => {
                        evt.preventDefault(); 
                        scrollToAnchor('team');
                    }}>Team</a>
                  </li>
                </ul>
                
                <ul className="alfa-footer--content_list">
                  <li className="alfa-footer--content_list-item">
                    <a className="alfa-footer--content_list-link" href="mailto:info@alfafastpitch.com">info@alfafastpitch.com</a>
                  </li>
                  <li className="alfa-footer--content_list-item">
                    <a className="alfa-footer--content_list-link" 
                      href="#partners"
                      onClick={(evt) => {
                        evt.preventDefault(); 
                        scrollToAnchor('partners');
                    }}>Partners</a>
                  </li>
                  <li className="alfa-footer--content_list-item">
                    <a 
                      className="alfa-footer--content_list-link" 
                      href="https://www.paypal.com/donate/?hosted_button_id=FWPGL2QAPWUPA" 
                      target="_blank" rel="noreferrer">Donate</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="alfa-footer--content_block questions">
            <div className="alfa-footer--content_block-wrapper">
              <p className="alfa-footer--content_info">Have more questions?</p>
              <p className="alfa-footer--content_info">Where here to help</p>
              <button className="alfa-action footer" data-secondary onClick={() => { window.open('https://share.hsforms.com/1jmio6mmoQdSRSfjV2OIs9Qcqy0n')}}>Contact Us</button>
            </div>
          </div>
        </div>

        <div className="alfa-footer--copyright">
          <div className="alfa-footer--copyright_details">&copy; ALFA - The Alliance of Fastpitch Athletes</div>
          {/* <div className="alfa-footer--copyright_details credit">Site Designed by <a href="https://www.studiosupermassive.com" className="ssm-link" rel="noreferrer" target="_blank">StudioSupermassive</a></div> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer;
