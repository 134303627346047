import React, {useEffect} from 'react';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Strip = () => {

  useEffect(() => {
    gsap.to(".alfa-strip--content", {
      scrollTrigger: {
        trigger: ".alfa-strip",
        start: "top 75%",
        // markers: true
      },
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
    
    gsap.to(".alfa-action.strip", {
      scrollTrigger: {
        trigger: ".alfa-strip",
        start: "top 75%",
        // markers: true
      },
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '50'
    });
  }, []);

  return (
    <div id="partners" className="alfa-strip">
      <div className="alfa-strip--content">
        
        <div className='alfa-strip--content_wrapper'>
          <div className="alfa-strip--content_left">
            <h3 className="alfa-strip--content_heading">Partnership</h3>
            <p className="alfa-strip--content_copy">
              ALFA actively seeks new partners who align themselves 
              with our mission of supporting fastpitch athletes nationwide. We offer several types of partnership 
              opportunities including Corporate, Individual, Vendor, Unrestricted Charitable Giving, Value in Kind 
              and customized programs that fit your specific goals and objectives.
            </p>
          </div>
          <div className="alfa-strip--content_right">
            <h3 className="alfa-strip--content_heading">Current Partners</h3>
            <p className='alfa-strip--content_partner'>
              {/* <h4 className="alfa-strip--content_subheading">Current Partners</h4> */}
              <a href="https://thealliancefastpitch.com" rel="noreferrer" target="_blank" >
                <img className="alfa-strip--partner" src='images/alfa-partner.png' alt='' width={'300px'} />
              </a>
            </p>
          </div>
        </div>
        <a href="https://share.hsforms.com/1R4PznQqeSEeS7I59qDyihgcqy0n" className="alfa-action strip" target="_blank" rel="noreferrer">Become a partner</a>        
      </div>
    </div>
  )
}

export default Strip;