import React, {useEffect, useState} from 'react';
import gsap from "gsap";

const Split2 = () => {
  const [viewport, setViewport] = useState(window.innerWidth);
  
  window.addEventListener('resize', () => {
    setTimeout(() => {
      setViewport(window.innerWidth);
    }, 500);
  });

  useEffect(() => {
    gsap.to(".alfa-split2--photo_program", {
      scrollTrigger: {
        trigger: ".alfa-split2",
        start: "top center",
        // markers: true
      },
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '-5%'
    });
    
    if(viewport >= 1900) {
      gsap.to(".alfa-split2--photo_featured", {
        scrollTrigger: {
          trigger: ".alfa-split2",
          start: "top center",
          // markers: true
        },
        delay: .5,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        top: '55%'
      });
    } else {
      gsap.to(".alfa-split2--photo_featured", {
        scrollTrigger: {
          trigger: ".alfa-split2",
          start: "top center",
          // markers: true
        },
        delay: .5,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        top: '45%'
      });
    }
    
    if(viewport >= 1900) {
      gsap.to(".alfa-split2--photo_pixel", {
        scrollTrigger: {
          trigger: ".alfa-split2",
          start: "top center",
          // markers: true
        },
        delay: .75,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        bottom: '100px'
      });
    }else if(viewport >= 1024) {
      gsap.to(".alfa-split2--photo_pixel", {
        scrollTrigger: {
          trigger: ".alfa-split2",
          start: "top center",
          // markers: true
        },
        delay: .75,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        bottom: '150px'
      });
    }else if(viewport >= 768) {
      gsap.to(".alfa-split2--photo_pixel", {
        scrollTrigger: {
          trigger: ".alfa-split2",
          start: "top center",
          // markers: true
        },
        delay: .75,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        bottom: '25px'
      });
    }else{
      gsap.to(".alfa-split2--photo_pixel", {
        scrollTrigger: {
          trigger: ".alfa-split2",
          start: "top center",
          // markers: true
        },
        delay: .75,
        duration: 1.5,
        ease: "power2.out",
        opacity: 1,
        bottom: '110px'
      });
    }
    
    gsap.to(".alfa-split2--content_header", {
      scrollTrigger: {
        trigger: ".alfa-split2",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });

    gsap.to(".alfa-split2--content_heading", {
      scrollTrigger: {
        trigger: ".alfa-split2",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });

    gsap.to(".alfa-split2--content_subheading", {
      scrollTrigger: {
        trigger: ".alfa-split2",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
   
    gsap.to(".alfa-split2--content_copy", {
      scrollTrigger: {
        trigger: ".alfa-split2",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });

    gsap.to(".alfa-split2--content_list", {
      scrollTrigger: {
        trigger: ".alfa-split2",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
    
    gsap.to(".alfa-action.split", {
      scrollTrigger: {
        trigger: ".alfa-split2",
        start: "top center",
        // markers: true
      },
      delay: 1,
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '0'
    });
  });

  return (
    <div id="programs" className="alfa-split2">
      <div className="alfa-split2--content">
        <div className="alfa-split2--content_wrapper">
          <p className="alfa-split2--content_header">Community Based Digital Platform</p>
          <h3 className="alfa-split2--content_heading">ALFA</h3>
          <p className="alfa-split2--content_copy">Become an ALFA member and gain access to our community-based digital platform, specifically created for current and former fastpitch athletes. ALFA's custom App and website allows members to maintain relationships across different teams, generations, and geographical locations, leverage job boards, mentorship programs and more all while remaining actively involved in the sport. Join ALFA, your team for life.</p>
          <h4 className="alfa-split2--content_subheading">Membership Options:</h4>
          <ul className="alfa-split2--content_list">
            <li>Pre-registration for free ALFA Membership (available today) </li>
            <li>Free & Premium ALFA Memberships (coming soon)</li>
          </ul>
          <button className="alfa-action split2" style={{"textTransform":"unset"}} data-secondary onClick={() => window.open('https://share.hsforms.com/1T_53xFRZTn-m5McvKIo8mwcqy0n')}>Pre-Register for ALFA</button>
        </div>
      </div>
      <div className="alfa-split2--photo">
        <div className="alfa-split2--photo_featured"></div>
        <div className="alfa-split2--photo_program"></div>
        <div className="alfa-split2--photo_pixel"></div>
      </div>
    </div>
  )
}

export default Split2;