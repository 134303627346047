import React from 'react'
import Logo from '../Logo/Logo';
import Menu from '../Menu/Menu';
import Players from '../Players/Players';
import Header from '../Header/Header';
import Overlay from '../Overlay/Overlay';

const Banner = () => {
  return (
    <div className="alfa-banner">
      <Logo />
      <Menu />
      <Overlay />
      <Players />
      <Header />
    </div>
  )
}

export default Banner;