import React, {useEffect, useRef} from 'react';
import gsap from "gsap";

const Players = () => {
  const alfaPlayerLeftRef = useRef(null);
  const alfaPlayerMiddleRef = useRef(null);
  const alfaPlayerRightRef = useRef(null);

  useEffect(() => {
    gsap.from(alfaPlayerLeftRef.current, {
      delay: .5,
      duration: 1,
      left: '0',
      opacity: 0,
    }, "-=1");
   
    gsap.from(alfaPlayerMiddleRef.current, {
      duration: 3,
      top: '65%',
      opacity: 0,
    });
    
    gsap.from(alfaPlayerRightRef.current, {
      delay: .5,
      duration: 1,
      right: '-25px',
      opacity: 0,
    }, "-=2");
  }, []);

  return (
    <div className="alfa-players">
      <img ref={alfaPlayerLeftRef} className="alfa-players--left" src="images/alpha-banner-fg-left.png" alt="" />
      <img ref={alfaPlayerMiddleRef} className="alfa-players--center" src="images/alpha-banner-fg-center.png" alt="" />
      <img ref={alfaPlayerRightRef} className="alfa-players--right" src="images/alpha-banner-fg-right.png" alt="" />
    </div>
  )
}

export default Players;