import React, {useEffect, useRef} from 'react';
import gsap from "gsap";

const Header = (props) => {
  const alfaHeaderRef = useRef(null);
  const alfaSubHeaderRef = useRef(null);

  useEffect(() => {
    gsap.to(alfaHeaderRef.current, {
      delay: 1.5,
      duration: 1.5,
      opacity: 1,
      top: '0'
    });
   
    gsap.to(alfaSubHeaderRef.current, {
      delay: 1.75,
      duration: 1.5,
      opacity: 1,
      top: '0'
    });
    
    gsap.to(".alfa-action.banner", {
      delay: 1.75,
      duration: 1.5,
      opacity: 1,
      top: '25px'
    });
  }, []);

  return (
    <React.Fragment>
      <div className="alfa-header">
        <h1 ref={alfaHeaderRef} className="alfa-header--primary">Your team for life</h1>
        <p ref={alfaSubHeaderRef} className="alfa-header--secondary">The Alliance of Fastpitch Athletes (ALFA) is a Not For Profit, 501(c)(3), that delivers programs and services to all fastpitch athletes through life’s journey.</p>
        <a href="https://www.paypal.com/donate/?hosted_button_id=FWPGL2QAPWUPA" className="alfa-action banner" target="_blank" rel="noreferrer">Donate</a>
      </div>
    </React.Fragment>
  )
}

export default Header;



