import React, {useEffect} from 'react'
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Introduction = () => {
  useEffect(() => {
    gsap.to(".alfa-introduction--heading", {
      scrollTrigger: {
        trigger: ".alfa-introduction",
        start: "top center",
        // markers: true
      },
      duration: 1.5,
      ease: "power2.out",
      opacity: 1,
      top: '-10px'
    });
    
    gsap.to(".alfa-introduction--subheading", {
      scrollTrigger: {
        trigger: ".alfa-introduction",
        start: "top center",
        // markers: true
      },
      delay: .5,
      duration: 2,
      ease: "power2.out",
      opacity: 2.5,
      top: '-10px'
    });
  }, []);

  return (
    <div id="about" className="alfa-introduction">
      <div className="alfa-introduction--content">
        <h2 className="alfa-introduction--heading">ALFA champions the personal and athletic growth across generations of fastpitch athletes. </h2>
        <p className="alfa-introduction--subheading">Whether it’s giving your time, donating, or voicing your commitment to support our mission, 
          ALFA has many ways to help you make an impact.</p>
      </div>
    </div>
  )
}

export default Introduction;